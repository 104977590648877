  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import Grid from "@mui/material/Grid";
  import Card from "@mui/material/Card";
  import styled, { css } from "styled-components";
  import Spinner from "./Spinner"; // Import the Spinner component

  import MDBox from "components/MDBox";
  import MDTypography from "components/MDTypography";
  import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
  import DashboardNavbar from "examples/Navbars/DashboardNavbar";
  import Footer from "examples/Footer";
  import Mapaimlocate from "./Mapaimlocate";
  import useAxios from "axiosHooks";

  const baseUrl = process.env?.REACT_APP_BASE_URL;

  const DarkBackground = styled.div`
    display: none;
    position: absolute;
    z-index: 999;
    padding: 5rem;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  
    ${({ disappear }) =>
      disappear &&
      css`
        display: block;
      `}
  `;

function Tables() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [counter, setCounter] = useState(0);
  const [zoom, setZoom] = useState(19);
  const [radius, setRadius] = useState(0.05);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [geostate, setGeostate] = useState([]);
  const [center, setCenter] = useState({ lat: 40.611057, lng: -111.899933 });
  const [prevSelectedLocation, setPrevSelectedLocation] = useState(null);
  const [locations, setLocationsData] = useState(null);
  const axios = useAxios();

    const getLocationData = async (url) => {
      setLoaded(false); // Set loading state to false before making the API call
      if (localStorage.getItem("selectedLocation") == null) {
        if (localStorage.getItem("lat") !== null) {
          url = `${baseUrl}/shp/locations/${localStorage
            .getItem("selectedLocation")}/radius?latitude=${localStorage.getItem(
            "lat"
          )}&longitude=${localStorage.getItem("lng")}&radius=0.05`;
        } else {
          url = `${baseUrl}/shp/locations/hq/data`;
        }
      }
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          const respConvertToJson = await response?.data;
          if (JSON.stringify(respConvertToJson?.features) !== JSON.stringify(geostate)) {
            setGeostate(respConvertToJson?.features);
          }
          setLoaded(true); // Set loading state to true after the API call is successful
        } else if (response.status === 401) {
          localStorage.removeItem("aimtoken");
          return navigate("/authentication/sign-in");
        }
      } catch (err) {
        setLoaded(true); // Set loading state to true even if the API call fails
      }
    };

    useEffect(() => {
      const storedLat = localStorage.getItem("lat");
      const storedLng = localStorage.getItem("lng");
      if (storedLat && storedLng) {
        setCenter({ lat: parseFloat(storedLat), lng: parseFloat(storedLng) });
      }
      getLocations();
    }, []);

    const getLocations = async () => {
      const resp = await axios.get(`${baseUrl}/auth/locations/`);
      if (resp?.status === 200) {
        setLocationsData(resp?.data || []);
      } else {
        setCompanys([]);
      }
    };

    useEffect(() => {
      let url = "";
      console.log("selectedLocation is", selectedLocation)
      if (selectedLocation.name !== "" && localStorage.getItem("lat") !== null) {
        switch (zoom) {
          case 19:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=0.1`;
            break;
          case 18:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=0.2`;
            break;
          case 17:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=0.4`;
            break;
          case 16:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=0.8`;
            break;
          case 15:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=1.6`;
            break;
          case 14:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=2`;
            break;
          case 13:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=4`;
            break;
          case 12:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=8`;
            break;
          case 11:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
              }/radius?latitude=${center?.lat}&longitude=${center?.long}&radius=16`;
            break;
          default:
            url = `${baseUrl}/shp/locations/${localStorage
              .getItem("selectedLocation")
            }/radius?latitude=${center?.lat}&longitude=${center?.lng}&radius=0.1`;
            break;
        }
        getLocationData(url);
      } else {
        getLocationData();
      }
    }, [center, selectedLocation, zoom]);

    // Separate useEffect for updating radius based on zoom level
    useEffect(() => {
      setRadius((prevRadius) => prevRadius * 2);
    }, [zoom]);

  return (
    <DashboardLayout>
      <MDBox height="40rem">
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <div>
                {!loaded && <Spinner />} {/* Render Spinner when not loaded */}
                {locations && (
                  <Mapaimlocate
                    getLocationData={getLocationData}
                    center={center}
                    zoom={zoom}
                    data={geostate}
                    setData={setGeostate}
                    setSelectedLocation={setSelectedLocation}
                    setCounter={setCounter}
                    setZoom={setZoom}
                    setCenter={setCenter}
                    setPrevSelectedLocation={setPrevSelectedLocation}
                    locations={locations}
                  />
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;

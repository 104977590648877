import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";


const baseURL = process.env.REACT_APP_BASE_URL;


const useAxios = () => {
    const navigate = useNavigate();
    const axiosInstance = axios.create({
        baseURL: baseURL
    })
    const valdiateToken = (token) => {
        const decodedToken = jwtDecode(token);
        const isExpired = dayjs.unix(decodedToken.exp || 0).diff(dayjs()) < 1
        return isExpired
    }
    axiosInstance.interceptors.request.use(async req=>{
        let token = localStorage.getItem('aimtoken');
        if(token && valdiateToken(token)){
            const refreshToken = localStorage?.getItem('aimrefresh')
            const refreshResp = await axios
            .post(`${baseURL}/auth/jwt/refresh/`, {
              refresh: refreshToken
            }).then(resp=>{
                localStorage.setItem('aimtoken', resp.data.access)
                req.headers["Authorization"]= `Bearer ${resp?.data?.access || ""}`
            }).catch(err=>{
                if(err?.response?.status === 401){
                    localStorage?.removeItem('aimrefresh')
                    localStorage?.removeItem('aimtoken')
                    navigate('/authentication/sign-in')
                }
            })
        }else{
            req.headers["Authorization"]= `Bearer ${token}`;
        }
        return req
    })
    return axiosInstance;
}

export default useAxios;
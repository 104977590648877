import React, { useEffect, useReducer, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAxios from "axiosHooks";
const baseUrl = process.env?.REACT_APP_BASE_URL;


export default function CustomModal(props) {
  const navigate = useNavigate();
  const axios = useAxios()
  const myRole = localStorage.getItem('role')
  const myid = localStorage.getItem('id')
  let linked_users = JSON.parse(localStorage.getItem('linked_users')) || []
  linked_users=[...linked_users, myid]?.map(item => parseInt(item))
  const username = localStorage.getItem('username')
  const initialState = {
    poi_name: "",
    titleErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    aim_postal: "",
    postalErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    stateValue: "",
    stateErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    countryValue: "",
    countryErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    cityValue: "",
    cityErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    remarks: "",
    remarkErrors: {
      hasErrors: false,
      errorMessage: "",
    },
  };

  const [state, dispatch] = useReducer(ReducerFuction, initialState);
  const shouldDisabled = (myRole?.toLowerCase() === 'viewer') && (!linked_users?.includes(parseInt(state?.created_by)))

  useEffect(() => {
    if ("id" in props.point) {
      let new_item={}
      for(let index in props.markers){
        let item = props.markers[index];
        if(item.id === props.point.id){
          new_item=item
          break;
        }
      }
      console.log('new_item', new_item)
      dispatch({
        type: "editData",
        value: new_item?.properties || {}
      })
    }
  }, [props]);
  function ReducerFuction(draft, action) {
    switch (action.type) {
      case "editData":
        Object.keys(action.value).forEach(item=>{
          draft[item] = action.value[item]
        })
        break;
      case "changeValue":
        draft[action.name] = action.value;
        break;
      case "titleChange":
        draft.poi_name = action.titleChosen;
        draft.titleErrors.hasErrors = false;
        draft.titleErrors.errorMessage = "";
        break;
      case "catchTitleErrors":
        if (action.titleChosen.length === 0) {
          draft.titleErrors.hasErrors = true;
          draft.titleErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "postalChange":
        draft.aim_postal = action.postalChosen;
        draft.postalErrors.hasErrors = false;
        draft.postalErrors.errorMessage = "";
        break;
      case "catchpostalErrors":
        if (action.postalChosen.length === 0) {
          draft.postalErrors.hasErrors = true;
          draft.postalErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "stateChange":
        draft.stateValue = action.stateChosen;
        draft.stateErrors.hasErrors = false;
        draft.stateErrors.errorMessage = "";
        break;
      case "catchstateErrors":
        if (action.stateChosen.length === 0) {
          draft.stateErrors.hasErrors = true;
          draft.stateErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "countryChange":
        draft.countryValue = action.countryChosen;
        draft.countryErrors.hasErrors = false;
        draft.countryErrors.errorMessage = "";
        break;
      case "catchcountryErrors":
        if (action.countryChosen.length === 0) {
          draft.countryErrors.hasErrors = true;
          draft.countryErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "cityChange":
        draft.cityValue = action.cityChosen;
        draft.cityErrors.hasErrors = false;
        draft.cityErrors.errorMessage = "";
        break;
      case "catchcityErrors":
        if (action.cityChosen.length === 0) {
          draft.cityErrors.hasErrors = true;
          draft.cityErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "remarkChange":
        draft.remarks = action.remarkChosen;
        draft.remarkErrors.hasErrors = false;
        draft.remarkErrors.errorMessage = "";
        break;
      case "catchremarkErrors":
        if (action.remarkChosen.length === 0) {
          draft.remarkErrors.hasErrors = true;
          draft.remarkErrors.errorMessage = "This field must not be empty";
        }
        break;
    }
    return {...draft}
  }

  const updateprops = () => {
    props.point.properties.aim_postal = state.aim_postal;
    props.point.properties.poi_name = state.poi_name;
    props.point.properties.country = state.countryValue;
    props.point.properties.city_name = state.cityValue;
    props.point.properties.remarks = state.remarks;
    props.point.properties.state_name = state.stateValue;
  };
  const formSubmit = async (e) => {
    const data = {
      bldg_name: state?.bldg_name,
      bldg_no: state?.bldg_no,
      bldg_type: state?.bldg_type,
      tot_flats: Number(state?.tot_flats),
      rd_name: state?.rd_name,
      block_n: state?.block_n,
      sub_loc_n: state?.sub_loc_n,
      locality: state?.locality,
      address: state?.address,
      no_floor: state?.no_floor,
      flats_floo: Number(state?.flats_floo),
      no_tower: Number(state?.no_tower),
      pin_code: state?.pin_code,
      city_name: state?.city_name,
      village_n: state?.village_n,
      tehsil_n: state?.tehsil_n,
      district_n: state?.district_n,
      state_name: props?.point?.properties?.state_name || state?.state_name,
      remarks: state?.remarks,
      longitude: props?.point?.lng,
      latitude: props?.point?.lat,
      featureid: state?.featureid,
      aim_postal: state?.aim_postal,
      aim_addres: state?.aim_addres,
      country: props?.point?.properties?.country || state?.country,
      poi_name: state.poi_name,
      location: props.locationId,
      geometry: {
        type: "Point",
        coordinates: [props?.point?.properties?.longitude, props?.point?.properties?.latitude],
      },
    };
    e.preventDefault();
    if (data.flats_floo !="" && data.no_floor !="") {
      if (data.flats_floo > data.no_floor) {
        window.alert("Flat floor cannot be more than total number of floor")
        return
      }
    }
    await axios.put(`${baseUrl}/shp/locations/${ localStorage.getItem("selectedLocation")}/data/${props.point.id}/`, data)
      .then((data)=>{
        // updateprops();
        for(let index in props.markers){
          let item = props.markers[index];
          if(item.id === props.point.id){
            props.markers[index]={...data.data};
            break;
          }
        }
      });
    props.setPoint({});
    props.toggle(false);
  };

  const cancel = () => {
    props.setPoint({});
    props.toggle(false);
  };

  return "id" in props.point ? (
    <div>
      <Modal
        isOpen={props.modal}
        style={{ "margin-top": "10rem", padding: "0px" }}
        toggle={props.toggle}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader style={{ background: "lightgray" }} toggle={props.toggle}>
          AimLocate Id: {props.point.properties.aim_addres}
        </ModalHeader>
        <ModalBody>
          <form style={{ maxHeight: "300px", overflowY: "auto" }}>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="latitude"
                  label="Latitude"
                  disabled
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={props?.point?.properties?.latitude}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="longitude"
                  label="Longitude"
                  disabled
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={props?.point?.properties?.longitude}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="buildingName"
                  label="Building Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.bldg_name}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "bldg_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="buildingNumber"
                  label="Building Number"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.bldg_no}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "bldg_no",
                      value: e?.target?.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="buildingType"
                  label="Building Type"
                  variant="outlined"
                  value={state.bldg_type}
                  style={{ margin: "1rem" }}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "bldg_type",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="tot_flats"
                  label="Total Flats"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.tot_flats}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "tot_flats",
                      value: e?.target?.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="rd_name"
                  label="Road Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.rd_name}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "rd_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="block_n"
                  label="Block Number"
                  variant="outlined"
                  value={state.block_n}
                  style={{ margin: "1rem" }}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "block_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="sub_loc_n"
                  label="Sub Location Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state.sub_loc_n}
                  disabled={shouldDisabled}
                  // defaultValue={props.point.properties.state_name}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "sub_loc_n",
                      value: e.target.value,
                    })
                  }
                  // onBlur={(e) =>
                  //   dispatch({
                  //     type: "catchstateErrors",
                  //     stateChosen: e.target.value,
                  //   })
                  // }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="locality"
                  label="Locality"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.locality}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "locality",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.address}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "address",
                      value: e.target.value, //?.replace(/\D/g,'')?.slice(0,6),
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="no_floor"
                  label="No Of Floor"
                  variant="outlined"
                  value={state.no_floor}
                  style={{ margin: "1rem" }}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "no_floor",
                      value: e.target.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="flats_floo"
                  label="Flat Floor"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.flats_floo}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "flats_floo",
                      value: e?.target?.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="no_tower"
                  label="Number Of Tower"
                  variant="outlined"
                  value={state.no_tower}
                  style={{ margin: "1rem" }}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "no_tower",
                      value: e?.target?.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="pin_code"
                  label="Postal Code"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state.pin_code}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "pin_code",
                      value: e.target.value?.replace(/\D/g,'').substring(0,6),
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="city_name"
                  label="City Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state.city_name}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "city_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="village_n"
                  label="Village Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.village_n}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "village_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="tehsil_n"
                  label="Tehsil Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.tehsil_n || ""}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "tehsil_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="district_n"
                  label="District Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.district_n || ""}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "district_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="featureid"
                  label="Feature Id"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.featureid || ""}
                  disabled={shouldDisabled}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "featureid",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="state_name"
                  label="State Name"
                  variant="outlined"
                  disabled
                  style={{ margin: "1rem" }}
                  value={props?.point?.properties?.state_name || state?.state_name || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "state_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="country"
                  label="Country"
                  variant="outlined"
                  disabled
                  style={{ margin: "1rem" }}
                  value={props?.point?.properties?.country || state?.country || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "country",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="aim_postal"
                  label="Aim Postal"
                  variant="outlined"
                  disabled
                  style={{ margin: "1rem" }}
                  value={state?.aim_postal || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "aim_postal",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="aim_addres"
                  label="Aim Address"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.aim_addres || ""}
                  disabled
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "aim_addres",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="poi_name"
                  label="POI Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.poi_name || ""}
                  disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "poi_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid container>
                <TextField
                  id="remarks"
                  label="Remarks"
                  fullWidth
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.remarks || ""}
                  disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "remarks",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </form>
        </ModalBody>
        <ModalFooter style={{ background: "#f7f0f0", "margin-top": "2rem" }}>
          {(!shouldDisabled && myRole?.toLowerCase() !== 'admin') &&
          <div>
            <Button color="primary" onClick={formSubmit}>
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={cancel}>
              Cancel
            </Button>
          </div>
          }
          {(myRole?.toLowerCase() === 'admin') &&
          <div>
            <Button color="primary" onClick={formSubmit}>
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={cancel}>
              Cancel
            </Button>
          </div>
          }
        </ModalFooter>
      </Modal>
    </div>
  ) : null;
}

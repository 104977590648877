import { MapContainer, TileLayer, LayersControl, useMap, useMapEvents } from "react-leaflet";
import React, { useEffect, useState, useCallback, useRef } from "react";
import "../../styles.css";
import MarkerCluster from "./MarkerCluster";
import CustomModal from "./CustomModal";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet-easybutton/src/easy-button.js";
import "leaflet.browser.print/dist/leaflet.browser.print.js";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";
import "leaflet-editable";
import CreateModal from "./CreateModal";
import CreateMultipleModal from "./CreateMultipleModal";
import "./leaflet-ruler.css";
import "./leaflet-ruler";
import * as GeoSearch from "leaflet-geosearch";
import useAxios from "axiosHooks";
import Spinner from "./Spinner"; // Import the Spinner component
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const baseUrl = process.env?.REACT_APP_BASE_URL;

const search = new GeoSearch.GeoSearchControl({
  provider: new GeoSearch.OpenStreetMapProvider(),
  style: 'button',
  showPopup: true
});

const MapEventHandler = ({ map }) => {
  const [additionalMarker, setAdditionalMarker] = useState(false);

  const listenForKeydown = useCallback((e) => {
    if (e.code === "MetaLeft") {
      setAdditionalMarker(true);
    }
  }, []);

  const listenForKeyup = useCallback((e) => {
    if (e.code === "MetaLeft") {
      setAdditionalMarker(false);
      setTimeout(() => {
        map?.editTools?.commitDrawing();
      }, 50);
    }
  }, [map]);

  useEffect(() => {
    document.addEventListener("keydown", listenForKeydown);
    document.addEventListener("keyup", listenForKeyup);

    return () => {
      document.removeEventListener("keydown", listenForKeydown);
      document.removeEventListener("keyup", listenForKeyup);
    };
  }, [listenForKeydown, listenForKeyup]);

  return null;
};

function Mapaimlocate({ data = [], setData, getLocationData, setCenter, setPrevSelectedLocation, ...rest }) {
  const [modal, setModal] = useState(false);
  const [shouldDelete, setShouldDelete] = useState(false);
  const [createmodal, setCreateModal] = useState(false);
  const [createMultiplemodal, setcreateMultiplemodal] = useState(false);
  const [markersToAdd, setmarkersToAdd] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  const multipleMarkers = useRef([]);
  const markerClicked = useRef('');
  const [markers, setMarkers] = useState([
    {
      lat: 40.611057,
      lng: -111.899933,
    }
  ]);
  const { locations } = rest;
  const [openDropdown, setopenDropdown] = useState(false);
  const [state, setState] = useState({
    center: [40, -111.899933],
    zoom: 19,
  });
  let toggle = () => setModal(!modal);
  let createtoggle = () => setCreateModal(!createmodal);
  let createmultitoggle = () => {
    multipleMarkers.current = [];
    setcreateMultiplemodal(!createMultiplemodal);
  };
  const [geostate, geosetState] = useState({});
  const [renderedonce, setrenderedonce] = useState(false);
  const [point, setPoint] = useState({});
  const [map, setMap] = useState(null);
  const [onceexecuted, setonceexecuted] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedDeleteLocation, setSelectedDeleteLocation] = useState([]);
  const [locationId, setLocationId] = useState();

  const whenCreated = useCallback((map) => {
    setMap(map);
    map.addControl(search);
    map.editable = true;
    L.control.ruler().addTo(map);
    L.control.browserPrint({ position: 'topright', title: 'Print ...' }).addTo(map);
  }, []);

  const onAddMarker = useCallback((e) => {
    let marker = map.editTools.startMarker(e);
    marker.on("click", function (e) {
      if (markerClicked.current !== 'single') return;
      setPoint(e.latlng);
      setCreateModal(true);
      e.target._icon.style.display = 'none';
    });
  }, [map]);

  const AddMarkers = useCallback(() => {
    map.getContainer().style.cursor = 'crosshair';

    if (!onceexecuted) {
      map.on("dblclick", function (e) {
        let pointers = document.querySelectorAll('img.leaflet-marker-icon.leaflet-zoom-animated');
        if (pointers.length > 1) {
          for (let points of pointers) {
            points.style.display = 'none';
          }
        }
        setPoint(e.latlng);
        setcreateMultiplemodal(true);
      });

      map.on("click", async function (e) {
        if (markerClicked.current !== 'multiple') return;

        const newMarker = L.marker(e.latlng).addTo(map);

        const fetchData = async () => {
          const response = await fetch(`https://api.geoapify.com/v1/geocode/reverse?lat=${e.latlng.lat}&lon=${e.latlng.lng}&apiKey=584335799aee4061ae288745f17a40fb`);
          const json = await response.json();

          const found = multipleMarkers.current.some(el => el.geometry.coordinates[0] === json['features'][0].properties.lat && el.geometry.coordinates[1] === json['features'][0].properties.lng);
          if (!found) {
            let obj = {
              "geometry": json['features'][0]['geometry'],
              "type": "Feature",
              country: json['features'][0]['properties']['country'],
              aim_postal: "",
              poi_name: "",
              state_name: json['features'][0]['properties']['state'],
              city_name: json['features'][0]['properties']['city'],
              remarks: "",
              longitude: e.latlng.lng,
              latitude: e.latlng.lat,
            };
            setmarkersToAdd(prev => {
              const existingMarker = prev.find(m => m.latitude === obj.latitude && m.longitude === obj.longitude);
              if (!existingMarker) {
                return [...prev, obj];
              }
              return prev;
            });
            multipleMarkers.current = [...multipleMarkers.current, obj];
          }
        };
        fetchData();
      });
      setonceexecuted(true);
    }
  }, [onceexecuted, map]);

  useEffect(() => {
    const storedLat = localStorage.getItem("lat");
    const storedLng = localStorage.getItem("lng");

    if (storedLat && storedLng) {
      const center = [parseFloat(storedLat), parseFloat(storedLng)];
      setMarkers([{ lat: center[0], lng: center[1] }]);
      setState(prevState => ({ ...prevState, center }));

      if (map) {
        map.flyTo(center, state.zoom);
      }
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const center = [position.coords.latitude, position.coords.longitude];
          setMarkers([{ lat: center[0], lng: center[1] }]);
          setState(prevState => ({ ...prevState, center }));

          if (map) {
            map.flyTo(center, state.zoom);
          }
        },
        () => {
          const defaultCenter = [40.611057, -111.899933];
          setMarkers([{ lat: defaultCenter[0], lng: defaultCenter[1] }]);
          setState(prevState => ({ ...prevState, center: defaultCenter }));
        }
      );
    } else {
      const defaultCenter = [40.611057, -111.899933];
      setMarkers([{ lat: defaultCenter[0], lng: defaultCenter[1] }]);
      setState(prevState => ({ ...prevState, center: defaultCenter }));
    }
  }, [map, state.zoom]);

  useEffect(() => {
    if (map) {
      map.on("moveend", handleMapMove);
    }
    return () => {
      if (map) {
        map.off("moveend", handleMapMove);
      }
    };
  }, [map]);

  const handleMapMove = useCallback(() => {
    const center = map.getCenter();
    localStorage.setItem("lat", center.lat);
    localStorage.setItem("lng", center.lng);
    setCenter(center);
  }, [map, setCenter]);

  useEffect(() => {
    const storedSelectedLocation = localStorage.getItem('selectedLocation');
    console.log("Stored selected location:", storedSelectedLocation);

    if (storedSelectedLocation) {
      setSelectedCity({ name: storedSelectedLocation });

      const foundLocation = locations.find((location) => location.name === storedSelectedLocation);
      if (foundLocation) {
        setLocationId(foundLocation.id);
        setState(prevState => ({ ...prevState, center: [foundLocation.lat, foundLocation.long] }));
      }
    } else if (selectedLocation.length > 0) {
      console.log("Selected location:", selectedLocation[0]);

      setSelectedCity({ name: selectedLocation[0].name });

      const foundLocation = locations.find((location) => location.name === selectedLocation[0].name);
      if (foundLocation) {
        setLocationId(foundLocation.id);
        setState(prevState => ({ ...prevState, center: [foundLocation.lat, foundLocation.long] }));
      }
    } else {
      setSelectedCity(null);
      setLocationId(null);
    }
  }, [locations, selectedLocation]);

  useEffect(() => {
    geosetState(data);
    const myRole = localStorage.getItem('role');
    if (map != null) {
      let groupbutton1 = L.easyButton('<i class="fa fa-map-pin fa-x" style="color: blue"></i>', {
        states: [{
          stateName: "add-markers",
          icon: "fa-map-marker",
          title: "Add random markers",
          onClick: function (control) {
            map.off('click');
            markerClicked.current = 'single';
            onAddMarker(markers);
          },
        }],
      });
      let groupbutton2 = L.easyButton("fa fa-object-group", {
        states: [{
          stateName: "add-multi-markers",
          icon: "fa-object-group",
          title: "Add multiple marker",
          onClick: function (control) {
            map.off('click');
            markerClicked.current = 'multiple';
            AddMarkers();
          },
        }],
      });
      let groupbutton3 = L.easyButton('<i class="fa fa-trash fa-2x" style="color: blue"></i>', {
        states: [{
          stateName: "d-none add-markers",
          icon: "fa-trash",
          title: "Delete markers",
          onClick: function (control) {
            setShouldDelete(true);
          },

        }],
      });

      if (!renderedonce) {
        groupbutton1.addTo(map);
        groupbutton2.addTo(map);
        groupbutton3.addTo(map);
        setrenderedonce(true);
      }
    }
  }, [data, map, onAddMarker, AddMarkers]);

  const resetGroupButtons = useCallback(() => {
    if (map) {
      markerClicked.current = '';
      map.getContainer().style.cursor = 'default';
    }
  }, [map]);

  const toggleMarkerSelection = useCallback((marker) => {
    setSelectedDeleteLocation((prevSelected) => {
      if (prevSelected.includes(marker)) {
        return prevSelected.filter((m) => m !== marker);
      } else {
        return [...prevSelected, marker];
      }
    });
  }, []);

  const deleteLocation = async () => {
    setShouldDelete(false);
    setIsDeleting(true);
    console.log("selectedDeleteLocation.length", selectedDeleteLocation.length)
    if (selectedDeleteLocation.length === 0) {
      alert("Please select at least one point to delete.");
      setIsDeleting(false);
      return;
    }

    try {
      const deletePromises = selectedDeleteLocation.map((item) => {
        return new Promise(async (resolve, reject) => {
          try {
            if (item.target._icon) {
              item.target._icon.remove(); // Remove the marker icon directly from the map
            }

            await axios.delete(`/shp/locations/${localStorage.getItem("selectedLocation")}/data/${item.id}/`);

            resolve();
          } catch (err) {
            reject(err);
          }
        });
      });
      await Promise.all(deletePromises);
      const updatedData = data.filter((x) => !selectedDeleteLocation.some((item) => item.id === x.id));
      setData(updatedData);
      setSelectedDeleteLocation([]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsDeleting(false);
    }
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  useEffect(() => {
    if (selectedLocation.length !== 0) {
      console.log("Setting localStorage with:", selectedLocation[0].name);
      localStorage.setItem('selectedLocation', selectedLocation[0].name);
    }
  }, [selectedLocation]);

  const handleLocationChange = useCallback((location, event) => {
  event.stopPropagation();
  console.log("Location selected:", location);
  setSelectedCity({ name: location.name });
  setState((prev) => ({ ...prev, center: [location.lat, location.long], zoom: 17 }));
  setCenter({ lat: location.lat, lng: location.long });
  setPrevSelectedLocation(selectedLocation);
  rest?.setCounter((prevCounter) => prevCounter + 1);
  rest?.setSelectedLocation({ ...location });
  setSelectedLocation([{ ...location }]);
  localStorage.setItem("selectedLocation", location.name);
  setLocationId(location.id);

  // Reset all parameters of child components
  setData([]);
  setMarkers([]);
  setPoint({});
  setSelectedDeleteLocation([]);
  setModal(false);
  setCreateModal(false);
  setcreateMultiplemodal(false);
  setmarkersToAdd([]);
  setIsDeleting(false);
  setLoading(false);

  if (map) {
    map.off("dblclick");
    map.off("click")
  }

  // Reset group button actions
  resetGroupButtons();

}, [selectedLocation, setCenter, setPrevSelectedLocation, rest, setData, resetGroupButtons]);


  const [selectedCity, setSelectedCity] = useState(() => {
    const storedSelectedLocation = localStorage.getItem('selectedLocation');
    if (storedSelectedLocation) {
      return { name: storedSelectedLocation };
    } else if (selectedLocation.length > 0) {
      return { name: selectedLocation[0].name };
    } else {
      return null;
    }
  });

  const modalStyle = {
    maxWidth: "500px",
    margin: "auto",
    padding: "1rem",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
  };

  const modalHeaderStyle = {
    background: "#f0f0f0",
    textAlign: "center",
    padding: "0.5rem",
    fontWeight: "bold",
    fontSize: "1.2rem",
  };

  const modalFooterStyle = {
    background: "#f0f0f0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const [activeBaseLayer, setActiveBaseLayer] = useState("Map");
  const handleBaseLayerChange = useCallback((e) => {
    setActiveBaseLayer(e.name);
  }, []);

  return (
    <div>
      <div style={{ marginBottom: "1px", textAlign: "center" }}>
        <Dropdown isOpen={openDropdown} toggle={() => setopenDropdown(!openDropdown)}>
          <DropdownToggle className="bg-light text-dark custom-dropdown-toggle" style={{ width: "200px" }}>
            {capitalizeFirstLetter(selectedCity?.name) || "Select Location"}
          </DropdownToggle>
          <DropdownMenu style={{ textAlign: "center", width: "200px" }}>
            {locations?.map(location => (
              <DropdownItem key={location.long} onClick={(e) => handleLocationChange(location, e)}>
                {capitalizeFirstLetter(location?.name)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <MapContainer center={state?.center || [40, -95.6268544]} zoom={state?.zoom || 5} whenCreated={whenCreated} editable>
        <MapEventHandler map={map} />
        <LayersControl onBaseLayerChange={handleBaseLayerChange} collapsed={false}>
          <LayersControl.BaseLayer checked={activeBaseLayer === "Map"} name="Map">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxNativeZoom={19}
              maxZoom={19}
            />
            <CustomModal setData={setData} modal={modal} toggle={toggle} point={point} setModal={setModal}
                        setPoint={setPoint} markers={data} locationId={locationId} />
            <CreateModal setData={setData} createmodal={createmodal} createtoggle={createtoggle} point={point} geostate={geostate}
                        setCreateModal={setCreateModal} geosetState={geosetState} markers={data}
                        locationId={locationId} />
            <CreateMultipleModal setData={setData} createMultiplemodal={createMultiplemodal} point={point} markers={data} geostate={geostate} locationId={locationId}
                                createmultitoggle={createmultitoggle} setcreateMultiplemodal={setcreateMultiplemodal} setonceexecuted={setonceexecuted}
                                geosetState={geosetState} markersToAdd={markersToAdd} map={map} setmarkersToAdd={setmarkersToAdd} />
          </LayersControl.BaseLayer>
          {loading ? <Spinner /> : data?.length ? (
            <MarkerCluster
              markers={data}
              setModal={setModal}
              setPoint={setPoint}
              selectedDeleteLocation={selectedDeleteLocation}
              setSelectedDeleteLocation={setSelectedDeleteLocation}
              toggleMarkerSelection={toggleMarkerSelection}
            />
          ) : null}
          <LayersControl.BaseLayer checked={activeBaseLayer === "Satellite"} name="Satellite">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
              maxNativeZoom={19}
              maxZoom={19}
            />
            <CustomModal setData={setData} modal={modal} toggle={toggle} point={point} setModal={setModal} setPoint={setPoint} markers={data} locationId={locationId} />
            <CreateModal setData={setData} createmodal={createmodal} createtoggle={createtoggle} point={point} geostate={geostate} locationId={locationId}
                        setCreateModal={setCreateModal} geosetState={geosetState} markers={data} />
            <CreateMultipleModal setData={setData} createMultiplemodal={createMultiplemodal} point={point} markers={data} geostate={geostate} locationId={locationId}
                                createmultitoggle={createmultitoggle} setcreateMultiplemodal={setcreateMultiplemodal} setonceexecuted={setonceexecuted}
                                geosetState={geosetState} markersToAdd={markersToAdd} map={map} setmarkersToAdd={setmarkersToAdd} />
          </LayersControl.BaseLayer>
        </LayersControl>
        <FullscreenControl position="topright" forceSeparateButton={true} />
        <Modal
          isOpen={shouldDelete}
          style={{ marginTop: "10rem" }}
          toggle={() => setShouldDelete(false)}
          modalTransition={{ timeout: 700 }}
          backdropTransition={{ timeout: 1300 }}
        >
          <ModalHeader style={modalHeaderStyle} toggle={() => setShouldDelete(false)}>
            <i className="fa fa-trash" style={{ marginRight: "0.5rem" }}></i>
            Delete Point(s)
          </ModalHeader>
          <ModalBody style={modalStyle}>
            {isDeleting ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div>
                Are you sure you want to delete the point(s)?
              </div>
            )}
          </ModalBody>
          <ModalFooter style={modalFooterStyle}>
            <Button color="primary" onClick={deleteLocation}>
              Yes
            </Button>
            <Button color="secondary" onClick={() => setShouldDelete(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <FlyMapTo center={state?.center} zoom={state?.zoom} setZoom={rest?.setZoom} />
      </MapContainer>
    </div>
  );
}

export default Mapaimlocate;

function FlyMapTo(props) {
  const map = useMap();
  useEffect(() => {
    map.flyTo(props?.center, props?.zoom);
  }, [props?.center, props?.zoom, map]);
  useMapEvents({
    zoomend() {
      const zoom = map.getZoom();
      props?.setZoom(zoom);
    },
  });
  return null;
}

import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label as BootLabel } from "reactstrap";
import Grid from "@mui/material/Grid";
import { TextField, Radio, FormControlLabel, RadioGroup, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAxios from "axiosHooks";
import { Label } from "@mui/icons-material";
import '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const baseUrl = process.env?.REACT_APP_BASE_URL;

const CreateModal = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const myRole = localStorage.getItem('role');
    const [openModal, setOpenModal] = useState(false);
    const [formUses, setFormUses] = useState('');
    const [selectedValue, setSelectedValue] = React.useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [state, setState] = useState({})
    const [companys, setCompanys] = useState([]);
    const [errors, setErros] = useState([]);
    const [resetEmail, setResetEmail] = useState('');
    const axios = useAxios();

    useEffect(() => {
        const aimtoken = localStorage.getItem('aimtoken');
        if (aimtoken) {
            getCompanys(aimtoken);
        }
    }, []);

    const getCompanys = async (aimtoken) => {
        try {
            const resp = await axios.get(`${baseUrl}/auth/companys/`, {
                headers: {
                    Authorization: `Bearer ${aimtoken}`
                }
            });
            if (resp?.status === 200) {
                setCompanys(resp?.data || []);
            } else {
                setCompanys([]);
            }
        } catch (error) {
            console.error('Error fetching companies', error);
            setCompanys([]);
        }
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleChanges = (event) => {
      setSelectedOption(event.target.value);
    };
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setOpenModal(true);
        },
        setForm: (name) => {
            setFormUses(name);
            handleCancle();
        },
    }));

    const handleSubmit = () => {
        if (formUses === 'newuser') return handleCreateNewUser();
        if (formUses === 'changePassword') return handleChangePassword();
        if (formUses === 'resetPassword') return handleResetPassword();
    };

    const handleChangePassword = async () => {
        setErros([]);
        let payload = {
            "old_password": state?.old_password,
            "new_password": state?.new_password,
            "company": selectedOption?.id,
            "user_email": state?.userEmail
        };

        try {
            const resp = await axios.post(`${baseUrl}/auth/change-password-user`, payload);
            if (resp?.status === 200) {
                alert("Password changed successfully.");
                setSelectedValue('');
                setSelectedOption('');
                setState({});
                setOpenModal(false);
            }
        } catch (error) {
            console.log('error', error);
            setErros(error?.response?.data);
            alert("Failed to change password. Please try again.");
        }
    };

    const handleResetPassword = async () => {
        setErros([]);
        try {
            const resp = await axios.post(`${baseUrl}/auth/reset-password`, { email: resetEmail });
            if (resp?.status === 200) {
                alert("Password reset done successfully.");
                setResetEmail('');
                setOpenModal(false);
            }
        } catch (error) {
            console.log('error', error);
            setErros(error?.response?.data);
            alert("Failed to reset password. Please try again.");
        }
    }

    const handleCreateNewUser = async() =>{
        setErros([])
        let payload = {
            "manager_email": state?.managerEmail  || '',
            "role": selectedValue,
            "company": selectedOption?.id,
            "username": state?.userEmail || '',
            "email": state?.userEmail
        }
        
        try {
            const resp = await axios.post(`${baseUrl}/auth/create-user`, payload)
            if(resp?.status === 201) {
                alert("User created successfully.");
                setSelectedValue('');
                setSelectedOption('');
                setState({});
                setOpenModal(false);
            }
        } catch (error) {
            console.log('error', error)
            alert("User creation failed. Contact Admin")
            setErros(error?.response?.data)
        }
    }

    const isValidEmail = email => {
        // Simple email validation pattern
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const checkDisabled = () => {
        switch (formUses) {
            case 'newuser':
                if(selectedValue === 'user'){
                    return !selectedOption?.id || !state?.userEmail || !state?.managerEmail
                }
                return !selectedValue || !selectedOption?.id || !state?.userEmail
            case 'changePassword':
                return !state?.old_password || !state?.new_password|| !selectedOption?.id || !state?.userEmail
            case 'resetPassword':
                return !resetEmail || !isValidEmail(resetEmail); // Check if email is empty or invalid
            default:
                return true;
        }
    }

    const handleCancle = () => {
        switch (formUses) {
            case 'newuser':
            case 'changePassword':
                setSelectedValue('');
                setSelectedOption('');
                setState({});
                setOpenModal(false);
                break;
            case 'resetPassword':
                setResetEmail(''); // Reset the resetEmail state
                setOpenModal(false); // Close the modal
                break;
            default:
                break;
        }
    }
    
    return (
        <div>
            <Modal
                isOpen={openModal}
                style={{ "margin-top": "10rem", padding: "0px" }}
                toggle={checkDisabled}
                modalTransition={{ timeout: 700 }}
                backdropTransition={{ timeout: 1300 }}
            >
                <ModalHeader style={{ background: "lightgray" }} toggle={props.createtoggle}>
                    {formUses === 'newuser' ? "Add New User" :
                      formUses === 'changePassword' ? "Change Password" :
                        "Reset Password" // Added title for Reset Password
                    }
                </ModalHeader>
                <ModalBody>
                    <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <form style={{ overflowY: "auto" }}>
                        {formUses === 'newuser' ? <><Grid container>
                            <Grid item xs={12} className="mt-2">
                                <FormControl  className="w-100">
                                    <InputLabel id="dropdown-label">Select Company</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="dropdown"
                                        value={selectedOption}
                                        label="Select Company"
                                        onChange={handleChanges}
                                    >
                                        {
                                            companys?.map((item, idx) => (
                                                <MenuItem key={idx} value={item}>{item?.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="userName"
                                        label="Manager Email"
                                        variant="outlined"
                                        className="w-100 mx-0"
                                        style={{ margin: "1rem" }}
                                        value={state['managerEmail']}
                                        onChange={(e) => { setState(prev => ({...prev, managerEmail: e?.target?.value}))}}
                                    />
                                    {errors['manager_email'] && <p className="text-danger ">{errors['manager_email']}</p>}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="managerName"
                                        label="User Name"
                                        variant="outlined"
                                        className="w-100 mx-0"
                                        style={{ margin: "1rem" }}
                                        value={state['userEmail']}
                                        onChange={(e) => { setState(prev => ({...prev, userEmail: e?.target?.value})) }}
                                    />
                                    {errors['email'] && <p className="text-danger ">{errors['email']}</p>}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <BootLabel>Role:</BootLabel>
                                    <RadioGroup
                                        aria-label="options"
                                        name="options"
                                        className="ps-1"
                                        value={selectedValue}
                                        onChange={handleChange}
                                        row
                                    >
                                        <FormControlLabel
                                            value="manager"
                                            control={<Radio />}
                                            label="Manager"
                                        />
                                        <FormControlLabel
                                            value="user"
                                            control={<Radio />}
                                            label="User"
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </> : formUses === 'changePassword' ? <><Grid container>
                            <Grid item xs={12} className="mt-2">
                                {/* <TextField
                                    id="Company"
                                    label="Company Name"
                                    variant="outlined"
                                    className="w-100 mx-0"
                                    //   value={props?.point?.lat}
                                    style={{ margin: "1rem" }}
                                /> */}
                                <FormControl  className="w-100">
                                    <InputLabel id="dropdown-label">Select Company</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="dropdown"
                                        value={selectedOption}
                                        label="Select Company"
                                        onChange={handleChanges}
                                    >
                                        {
                                            companys?.map((item, idx) => (
                                                <MenuItem key={idx} value={item}>{item?.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="userName"
                                        label="User Email"
                                        variant="outlined"
                                        className="w-100 mx-0"
                                        style={{ margin: "1rem" }}
                                        onChange={(e) => { setState(prev => ({...prev, userEmail: e?.target?.value}))}}
                                    />
                                    {errors['user_email'] && <p className="text-danger ">{errors['user_email']}</p>}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="oldPassword"
                                        label="Old Password"
                                        type="password"
                                        variant="outlined"
                                        className="w-100 mx-0"
                                        style={{ margin: "1rem" }}                                        
                                        onChange={(e) => {setState(prev => ({...prev, old_password: e?.target?.value}))}} 
                                    />
                                    {errors['old_password'] && <p className="text-danger ">{errors['old_password']}</p>}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newPassword"
                                        label="New Password"
                                        type="password"
                                        variant="outlined"
                                        className="w-100 mx-0"
                                        style={{ margin: "1rem" }}                                        
                                        onChange={(e) => {setState(prev => ({...prev, new_password: e?.target?.value}))}}
                                    />
                                </Grid>
                            </Grid>
                        </> : <Grid container> {/* New form for resetPassword */}
                            <Grid item xs={12}>
                                <TextField
                                  id="resetEmail"
                                  label="Email"
                                  variant="outlined"
                                  className="w-100 mx-0"
                                  style={{ margin: "1rem" }}
                                  value={resetEmail}
                                  onChange={(e) => setResetEmail(e.target.value)}
                                />
                                {errors['email'] && <p className="text-danger ">{errors['email']}</p>}
                            </Grid>
                        </Grid>
                        }
                    </form>
                    </ThemeProvider>
                </ModalBody>
                <ModalFooter style={{ background: "#f7f0f0", "margin-top": "2rem" }}>
                    <div>
                        <Button 
                            color="primary"
                            onClick={handleSubmit} 
                            disabled={checkDisabled()}
                        >
                            Submit
                        </Button>{" "}
                        <Button color="secondary" onClick={handleCancle} >
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
})

export default CreateModal;

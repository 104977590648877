import React, { useEffect, useReducer, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAxios from "axiosHooks";
import Spinner from "./Spinner"; // Import the spinner component
const baseUrl = process.env?.REACT_APP_BASE_URL;

export default function CreateModal(props) {
  const navigate = useNavigate();
  const myRole = localStorage.getItem('role')
  const axios = useAxios()
  const initialState = {
    poi_name: "",
    titleErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    postalValue: "",
    postalErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    stateValue: "",
    stateErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    countryValue: "",
    countryErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    cityValue: "",
    cityErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    remarkValue: "",
    remarkErrors: {
      hasErrors: false,
      errorMessage: "",
    },
  };
  const [state, dispatch] = useReducer(ReducerFuction, initialState);
  const [value, setValue] = useState({ state: "", country: "", city: "" });
  const [newmarker, setMarker] = useState({});
  const [getdefualtvalue, setdefaultvalue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const extractAimAddressAndZip = (latitude, longitude) => {
    // Removing the decimal point and any negative sign for processing
    const latProcessed = latitude.replace('.', '').replace('-', '');
    const longProcessed = longitude.replace('.', '').replace('-', '');

    // Extracting the first three digits for the zip code
    const latZip = latProcessed.substring(0, 3);
    const longZip = longProcessed.substring(0, 3);

    // Extracting the next four digits for the aim address
    const latAddress = latProcessed.substring(3, 7);
    const longAddress = longProcessed.substring(3, 7);

    const aim_address = `${latAddress}${longAddress}`;
    const zipCode = `${latZip}${longZip}`;

    return { aim_address, zipCode };
  };


  useEffect(() => {
    if (props.point.lat != null) {
      const latitude = props.point.lat.toString().split('.')
      const longitude = props.point.lng.toString().split('.')
      const { aim_address, zipCode } = extractAimAddressAndZip(props.point.lat.toString(), props.point.lng.toString());
      const firstZip = (Math.abs(props?.point?.lat*100))?.toString()?.substring(0,3)
      const lastZip = (Math.abs(props?.point?.lng*100))?.toString()?.substring(0,3)

      dispatch({
        type: "addAimAddress",
        value: aim_address,
      })
      dispatch({
        type: "addAimPostal",
        value: zipCode,
      })
      fetch(
        `https://api.geoapify.com/v1/geocode/reverse?lat=${props.point.lat}&lon=${props.point.lng}&apiKey=584335799aee4061ae288745f17a40fb`
      )
        .then((res) => res.json())
        .then((json) => {
          if (json?.features[0]?.properties?.formatted.indexOf(',') !== -1) {
            let segments = json?.features[0]?.properties?.formatted.split(',').length;
            console.log("segments are", segments)
          }
          const street = `${json?.features[0]?.properties?.street || ''}`;
          dispatch({
            type: "changeValue",
            name: "rd_name",
            value: street,
          })
          const houseno = `${json?.features[0]?.properties?.housenumber || ''}`;
          dispatch({
            type: "changeValue",
            name: "bldg_no",
            value: houseno,
          })
          const suburb = `${json?.features[0]?.properties?.suburb || ''}`;
          dispatch({
            type: "changeValue",
            name: "locality",
            value: suburb,
          })
          const postcode = `${json?.features[0]?.properties?.postcode || ''}`;
          dispatch({
            type: "changeValue",
            name: "pin_code",
            value: postcode,
          })
          const city = `${json?.features[0]?.properties?.city || ''}`;
          dispatch({
            type: "changeValue",
            name: "city_name",
            value: city,
          })
          const county = `${json?.features[0]?.properties?.county || ''}`;
          dispatch({
            type: "changeValue",
            name: "district_n",
            value: county,
          })
          const address = `${json?.features[0]?.properties?.address_line1 || ''}, ${json?.features[0]?.properties?.address_line2 || ''}`;
          dispatch({
            type: "changeValue",
            name: "address",
            value: address,
          })
          setValue(json.features[0].properties);
        })
        .catch((error) => console.log(error));
    }
  }, [props.point.lat, props.point.lng]);

  function ReducerFuction(draft, action) {
    switch (action.type) {
      case "changeValue":
        draft[action.name] = action.value;
        break;
      case "addAimAddress":
        draft.aim_addres = action.value;
        break;
      case "addAimPostal":
        draft.aim_postal = action.value;
        break;
      case "titleChange":
        draft.poi_name = action.titleChosen;
        draft.titleErrors.hasErrors = false;
        draft.titleErrors.errorMessage = "";
        break;
      case "catchTitleErrors":
        if (action.titleChosen.length === 0) {
          draft.titleErrors.hasErrors = true;
          draft.titleErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "postalChange":
        draft.postalValue = action.postalChosen;
        draft.postalErrors.hasErrors = false;
        draft.postalErrors.errorMessage = "";
        break;
      case "catchpostalErrors":
        if (action.postalChosen.length === 0) {
          draft.postalErrors.hasErrors = true;
          draft.postalErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "stateChange":
        draft.stateValue = action.stateChosen;
        draft.stateErrors.hasErrors = false;
        draft.stateErrors.errorMessage = "";
        break;
      case "catchstateErrors":
        if (action.stateChosen.length === 0) {
          draft.stateErrors.hasErrors = true;
          draft.stateErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "countryChange":
        draft.countryValue = action.countryChosen;
        draft.countryErrors.hasErrors = false;
        draft.countryErrors.errorMessage = "";
        break;
      case "catchcountryErrors":
        if (action.countryChosen.length === 0) {
          draft.countryErrors.hasErrors = true;
          draft.countryErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "cityChange":
        draft.cityValue = action.cityChosen;
        draft.cityErrors.hasErrors = false;
        draft.cityErrors.errorMessage = "";
        break;
      case "catchcityErrors":
        if (action.cityChosen.length === 0) {
          draft.cityErrors.hasErrors = true;
          draft.cityErrors.errorMessage = "This field must not be empty";
        }
        break;
      case "remarkChange":
        draft.remarkValue = action.remarkChosen;
        draft.remarkErrors.hasErrors = false;
        draft.remarkErrors.errorMessage = "";
        break;
      case "catchremarkErrors":
        if (action.remarkChosen.length === 0) {
          draft.remarkErrors.hasErrors = true;
          draft.remarkErrors.errorMessage = "This field must not be empty";
        }
        break;
    }
    return {...draft}
  }

  const cancel = () => {
    props.setCreateModal(false);
    props.createtoggle(false);
  };

  const formSubmit = async (e) => {

    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      bldg_name: state?.bldg_name,
      bldg_no: state?.bldg_no,
      bldg_type: state?.bldg_type,
      tot_flats: Number(state?.tot_flats),
      rd_name: state?.rd_name,
      block_n: state?.block_n,
      sub_loc_n: state?.sub_loc_n,
      locality: state?.locality,
      address: state?.address,
      no_floor: state?.no_floor,
      flats_floo: Number(state?.flats_floo),
      no_tower: Number(state?.no_tower),
      pin_code: state?.pin_code,
      city_name: state?.city_name,
      village_n: state?.village_n,
      tehsil_n: state?.tehsil_n,
      district_n: state?.district_n,
      state_name: value?.state,
      remarks: state?.remarks,
      longitude: props?.point?.lng,
      latitude: props?.point?.lat,
      featureid: state?.featureid,
      aim_postal: state?.aim_postal,
      aim_addres: state?.aim_addres,
      country: value.country,
      poi_name: state.poi_name,
        location: props.locationId,
      geometry: {
        type: "Point",
        coordinates: [props?.point?.lng, props?.point?.lat],
      },
      created_by: parseInt(localStorage.getItem('id'))
    };

    if (data.flats_floo !="" && data.no_floor !="") {
      if (data.flats_floo > data.no_floor) {
        window.alert("Flat floor cannot be more than total number of floor")
        return
      }
    }

    await axios.post(`${baseUrl}/shp/locations/${ localStorage.getItem("selectedLocation")}/data/`, data)
      .then((data) => {
        props.markers.push(data?.data);
        props.geosetState(data?.data);
        setdefaultvalue(data?.data);
        props.setCreateModal(false);
        props.createtoggle(false);
        setIsSubmitting(false);
        // window.location?.reload()
      })
      .catch((err) => {
        console.log(err.message);
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <Modal
        isOpen={props.createmodal}
        style={{ "margin-top": "10rem", padding: "0px" }}
        toggle={props.createtoggle}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader style={{ background: "lightgray" }} toggle={props.createtoggle}>

          {isSubmitting ? `Please wait...Submitting data` : `Fill in the details`}
        </ModalHeader>
        <ModalBody>
          <form style={{ maxHeight: "300px", overflowY: "auto" }}>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="latitude"
                  label="Latitude"
                  disabled
                  variant="outlined"
                  value={props?.point?.lat}
                  style={{ margin: "1rem" }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="longitude"
                  label="Longitude"
                  disabled
                  variant="outlined"
                  value={props?.point?.lng}
                  style={{ margin: "1rem" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="buildingName"
                  label="Building Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  defaultValue={state?.bldg_name}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "bldg_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                    id="buildingNumber"
                    label="Building Number"
                    variant="outlined"
                    style={{ margin: "1rem" }}
                    value={state?.bldg_no || ""}
                    // disabled={myRole?.toLowerCase() === 'viewer'}
                    onChange={(e) =>
                      dispatch({
                        type: "changeValue",
                        name: "bldg_no",
                        value: e?.target?.value?.replace(/\D/g,''),
                      })
                    }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="buildingType"
                  label="Building Type"
                  variant="outlined"
                  value={state.bldg_type}
                  style={{ margin: "1rem" }}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "bldg_type",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="tot_flats"
                  label="Total Flats"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.tot_flats}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "tot_flats",
                      value: e?.target?.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
              <TextField
                  id="rd_name"
                  label="Road Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.rd_name || ""}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "rd_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="block_n"
                  label="Block Number"
                  variant="outlined"
                  value={state.block_n}
                  style={{ margin: "1rem" }}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "block_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
              <TextField
                  id="sub_loc_n"
                  label="Sub Location Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state.sub_loc_n}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "sub_loc_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="locality"
                  label="Locality"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.locality || ""}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "locality",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="no_floor"
                  label="No Of Floor"
                  variant="outlined"
                  value={state.no_floor}
                  style={{ margin: "1rem" }}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "no_floor",
                      value: e.target.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
              {/* <Grid item xs={6}>
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.address}
                  disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "address",
                      value: e.target.value, //?.replace(/\D/g,'')?.slice(0,6),
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={10}>
                <TextField
                  id="address"
                  label="Address"
                  fullWidth
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.address || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "address",
                      value: e.target.value, //?.replace(/\D/g,'')?.slice(0,6),
                    })
                  }
                />
              </Grid>

            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="flats_floo"
                  label="Flat Floor"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.flats_floo}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "flats_floo",
                      value: e?.target?.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="no_tower"
                  label="Number Of Tower"
                  variant="outlined"
                  value={state.no_tower}
                  style={{ margin: "1rem" }}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "no_tower",
                      value: e?.target?.value?.replace(/\D/g,''),
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="pin_code"
                  label="Postal Code"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.pin_code || ""}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "pin_code",
                      value: e.target.value?.replace(/\D/g,'').substring(0,6),
                    })
                  }
                />
              </Grid>

              <Grid item xs={6}>
              <TextField
                  id="city_name"
                  label="City Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.city_name || ""}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "city_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="village_n"
                  label="Village Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.village_n}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "village_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="tehsil_n"
                  label="Tehsil Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.tehsil_n || ""}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "tehsil_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="district_n"
                  label="District Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.district_n || ""}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "district_n",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="featureid"
                  label="Feature Id"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.featureid || ""}
                  // disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "featureid",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="state_name"
                  label="State Name"
                  variant="outlined"
                  disabled
                  style={{ margin: "1rem" }}
                  value={value?.state || state?.state_name || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "state_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="country"
                  label="Country"
                  variant="outlined"
                  disabled
                  style={{ margin: "1rem" }}
                  value={value?.country || state?.country || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "country",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="aim_postal"
                  label="Aim Postal"
                  variant="outlined"
                  disabled
                  style={{ margin: "1rem" }}
                  value={state?.aim_postal || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "aim_postal",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="aim_addres"
                  label="Aim Address"
                  variant="outlined"
                  disabled
                  style={{ margin: "1rem" }}
                  value={state?.aim_addres || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "aim_addres",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="poi_name"
                  label="POI Name"
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.poi_name || ""}
                  disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "poi_name",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid container>
                <TextField
                  id="remarks"
                  label="Remarks"
                  fullWidth
                  variant="outlined"
                  style={{ margin: "1rem" }}
                  value={state?.remarks || ""}
                  disabled={myRole?.toLowerCase() === 'viewer'}
                  onChange={(e) =>
                    dispatch({
                      type: "changeValue",
                      name: "remarks",
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </form>
        </ModalBody>
        <ModalFooter style={{ background: "#f7f0f0", "margin-top": "2rem" }}>
           <div>
            <Button color="primary" onClick={formSubmit}>
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={cancel}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

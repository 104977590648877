// components/Signout.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Signout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear localStorage
    localStorage.removeItem("aimtoken");
    localStorage.removeItem("aimrefresh");

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear application cache (for browsers that support it)
    if ('caches' in window) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
    }

    // Manually nullify variables and data structures if any
    // e.g., if you have any global state, you can reset it here

    // Navigate to the sign-in page
    navigate('/authentication/sign-in', { replace: true });

    // You can also force a page reload to ensure all memory is cleared
    window.location.reload();
  }, [navigate]);

  return null;
};

export default Signout;

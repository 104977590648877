import { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Avatar, TextField } from "@mui/material";
import axios from "axios";
import MDSnackbar from "../../../components/MDSnackbar";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "assets/images/logo1.jpg";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Spinner from "../../tables/Spinner";

const client_id = "698a677882080a6d7366";
const baseUrl = process.env?.REACT_APP_BASE_URL;

function Basic(props) {
  const navigate = useNavigate();
  const initialState = {
    name: "",
    nameErrors: { hasErrors: false, errorMessage: "" },
    password: "",
    passwordErrors: { hasErrors: false, errorMessage: "" },
  };

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [state, dispatch] = useReducer(ReducerFuction, initialState);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Login successful"
      content="Loading maps..."
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error Logging"
      content="Incorrect username/password"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const validEmail = validateEmail(state.name);
    if (!validEmail) {
      dispatch({ type: "emailValidError" });
      setLoading(false);
      return;
    }
    if (!state.nameErrors.hasErrors && !state.passwordErrors.hasErrors) {
      const controller = new AbortController();
      const signal = controller.signal;

      fetch(`${baseUrl}/auth/jwt/create`, {
        method: "POST",
        body: JSON.stringify({ email: state.name, password: state.password }),
        headers: { "Content-type": "application/json; charset=UTF-8" },
        signal,
      })
        .then(async (response) => {
          if (response.status === 200) {
            const data = await response.json();
            localStorage.setItem("aimtoken", data["access"]);
            localStorage.setItem("aimrefresh", data["refresh"]);
            const headers = { Authorization: `Bearer ${localStorage.getItem("aimtoken")}` };

            axios.get(`${baseUrl}/auth/users/me/`, { headers }).then((resp) => {
              localStorage.setItem('role', resp?.data?.role);
              localStorage.setItem('id', resp?.data?.id);
              localStorage.setItem('linked_users', JSON.stringify(resp?.data?.linked_users));
            });

            setSuccessSB(true);
            navigate("/maps");
          } else {
            setErrorSB(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
          if (err.name !== 'AbortError') {
            setErrorSB(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });

      return () => controller.abort();
    }
  };

  function ReducerFuction(draft, action) {
    switch (action.type) {
      case "namechange":
        draft.name = action.namechosen;
        draft.nameErrors.hasErrors = false;
        draft.nameErrors.errorMessage = "";
        break;
      case "passwordchange":
        draft.password = action.passwordChosen;
        draft.passwordErrors.hasErrors = false;
        draft.passwordErrors.errorMessage = "";
        break;
      case "nameTitleErrors":
        if (action.nameChosen.length === 0) {
          draft.nameErrors.hasErrors = true;
          draft.nameErrors.errorMessage = "This field must not be blank";
        }
        break;
      case "emailValidError":
        draft.nameErrors.hasErrors = true;
        draft.nameErrors.errorMessage = "Email is not valid";
        break;
      case "catchpasswordErrors":
        if (action.passwordChosen.length === 0) {
          draft.passwordErrors.hasErrors = true;
          draft.passwordErrors.errorMessage = "This field must not be blank";
        }
        break;
      default:
        break;
    }
    return { ...draft };
  }

  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        {loading && <Spinner />} {/* Conditionally render spinner */}
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Grid container justifyContent="center">
            <Avatar src={logo} sx={{ width: 100, height: 100 }} />
          </Grid>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}></Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <TextField
                type="email"
                label="Email"
                onChange={(e) => dispatch({ type: "namechange", namechosen: e.target.value })}
                onBlur={(e) => dispatch({ type: "nameTitleErrors", nameChosen: e.target.value })}
                error={!!state.nameErrors.hasErrors}
                helperText={state.nameErrors.errorMessage}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <TextField
                type="password"
                label="Password"
                onChange={(e) => dispatch({ type: "passwordchange", passwordChosen: e.target.value })}
                onBlur={(e) => dispatch({ type: "catchpasswordErrors", passwordChosen: e.target.value })}
                error={!!state.passwordErrors.hasErrors}
                helperText={state.passwordErrors.errorMessage}
                fullWidth
              />
            </MDBox>
            {renderSuccessSB}
            {renderErrorSB}
            <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={(e) => handleSubmit(e)} fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >

                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useLeaflet, MapContainer, useMap } from "react-leaflet";

import CustomModal from "./CustomModal";



const mcg = L.markerClusterGroup({ maxClusterRadius: 10, showCoverageOnHover: true, animate: true});
let position = {}

const MarkerCluster = (props) => {
  const map  = useMap();
  const customMarker = L.divIcon({
    iconSize: [20, 20],
    className: 'myDivIcon'
  });
  const markers = props.markers? props.markers:[]

  const [selectedFeature, setSelectedFeature] = useState({});
  const [show, setShow] = React.useState(false);
  const [point,setPoint] = React.useState({})
  const [clicks, setClicks] = useState({count: 0})
  const myRole = localStorage.getItem('role')
  const myid = localStorage.getItem('id')
  let linked_users = JSON.parse(localStorage.getItem('linked_users')) || []
  linked_users = [...linked_users, myid]?.map(item => parseInt(item))

  const setData = (id, target) => {
    const isSelected = props.selectedDeleteLocation.some(item => item.id === id);
    if (isSelected) {
      target._icon.childNodes[0].style.color = "blue";
      props.setSelectedDeleteLocation(prev => prev.filter(item => item.id !== id));
    } else {
      target._icon.childNodes[0].style.color = "red";
      document.querySelectorAll('.add-markers-active')?.forEach(item => item?.classList?.remove('d-none'))
      props.setSelectedDeleteLocation(prev => ([...prev, { id, target }]));
    }
  }

  useEffect(() => {
    let singleClickTimer;
    if (clicks.count === 1) {
      singleClickTimer = setTimeout(function () {
        setData(clicks?.position?.id, clicks.targate)
        setClicks(0);
      }, 250);
    } else if (clicks.count === 2) {
      props.setModal(true)
      props.setPoint(clicks.position)
      setClicks(0);
    }
    return () => clearTimeout(singleClickTimer);
  }, [clicks.count]);

  useEffect(() => {
    mcg.clearLayers();
    markers.forEach((position, index) => {
      const isSelected = props.selectedDeleteLocation.some(item => item.id === position.id);
      customMarker.options.html = `<i class="fa fa-circle" id="mapPin${position.id}" style="color: ${isSelected ? 'red' : ((myRole?.toLowerCase() === 'viewer') && (linked_users?.includes(parseInt(position?.properties?.created_by)))) ? 'green' : 'blue'}"></i>`
      L.marker(new L.LatLng(position.properties.latitude, position.properties.longitude, { draggable: 'true' }), {
        icon: customMarker
      })
        .addTo(mcg)
        .bindTooltip('Double click to get details')
        .on('dblclick', () => {
          setClicks({ count: 2, position })
        })
        .on('click', (e) => {
          console.log(e.target)
          setClicks({ count: 1, position, targate: e.target })
        })
    });
    map.addLayer(mcg);
  }, [markers.length, props.selectedDeleteLocation]);

  return null;
};

MarkerCluster.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.objectOf(PropTypes.number),
      text: PropTypes.string
    }).isRequired
  ).isRequired,
  setSelectedDeleteLocation: PropTypes.func.isRequired,
  selectedDeleteLocation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      target: PropTypes.object.isRequired
    })
  ).isRequired,
  setModal: PropTypes.func.isRequired,
  setPoint: PropTypes.func.isRequired
};

export default MarkerCluster;

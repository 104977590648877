/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useReducer, useState } from "react";
import MDSnackbar from "../../../components/MDSnackbar";
import { TextField } from "@mui/material";
const baseUrl = process.env?.REACT_APP_BASE_URL;

function Cover() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="New User Creation"
      content="Account creation processing..."
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const initialState = {
    name: "",
    password: "",
    email: "",
    nameErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    passwordErrors: {
      hasErrors: false,
      errorMessage: "",
    },
    emailErrors: {
      hasErrors: false,
      errorMessage: "",
    },
  };
  const [state, dispatch] = useReducer(ReducerFuction, initialState);
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const checkValidation = () => {
    const valid = true;
    if (!validateEmail(state.email)) {
      dispatch({
        type: "emailValidationError",
      });
      valid = valid && false;
    }
    if (!state.name) {
      dispatch({
        type: "nameTitleErrors",
        nameChosen: state.name || "",
      });
      valid = valid && false;
    }
    return valid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkValidation()) {
      return;
    }
    if (
      !state.nameErrors.hasErrors &&
      !state.passwordErrors.hasErrors &&
      !state.emailErrors.hasErrors
    ) {
      fetch(`${baseUrl}/auth/users/`, {
        method: "POST",
        body: JSON.stringify({
          username: state.name,
          email: state.email,
          password: state.password,
          location: "navajo",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(async (response) => {
          if (response.status === 201) {
            response.json();
            setSuccessSB(true);
            await delay(5000);
            setSuccessSB(false);
            navigate("/aign-in");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  function ReducerFuction(draft, action) {
    switch (action.type) {
      case "namechange":
        draft.name = action.namechosen;
        draft.nameErrors.hasErrors = false;
        draft.nameErrors.errorMessage = "";
        break;
      case "emailchange":
        draft.email = action.emailchosen;
        draft.emailErrors.hasErrors = false;
        draft.emailErrors.errorMessage = "";
        break;
      case "passwordchange":
        draft.password = action.passwordChosen;
        draft.passwordErrors.hasErrors = false;
        draft.passwordErrors.errorMessage = "";
        break;
      case "acceptTermsAndCondition":
        draft.acceptTAndC = action.value;
        break;
      case "nameTitleErrors":
        if (action.nameChosen.length === 0) {
          draft.nameErrors.hasErrors = true;
          draft.nameErrors.errorMessage = "This field must not be blank";
        }
        break;
      case "catchpasswordErrors":
        if (action.passwordChosen.length === 0) {
          draft.passwordErrors.hasErrors = true;
          draft.passwordErrors.errorMessage = "This field must not be blank";
        }
        break;
      case "emailValidationError":
        draft.emailErrors.hasErrors = true;
        draft.emailErrors.errorMessage = "Email is not valid";
        break;
      case "catchemailErrors":
        if (action.emailchosen.length === 0) {
          draft.emailErrors.hasErrors = true;
          draft.emailErrors.errorMessage = "This field must not be blank";
        }
        break;
    }
    return {...draft}
  }

  return (
    <>
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Join us today
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your name, email and password to register
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <TextField
                  type="text"
                  label="Name"
                  variant="standard"
                  id="name"
                  onChange={(e) =>
                    dispatch({
                      type: "namechange",
                      namechosen: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "nameTitleErrors",
                      nameChosen: e.target.value,
                    })
                  }
                  error={state.nameErrors.hasErrors ? true : false}
                  helperText={state.nameErrors.errorMessage}
                  fullWidth
                  required
                />
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  type="email"
                  label="Email"
                  variant="standard"
                  id="email"
                  onChange={(e) =>
                    dispatch({
                      type: "emailchange",
                      emailchosen: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "catchemailErrors",
                      emailchosen: e.target.value,
                    })
                  }
                  error={state.emailErrors.hasErrors ? true : false}
                  helperText={state.emailErrors.errorMessage}
                  fullWidth
                  required
                />
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  type="password"
                  label="Password"
                  variant="standard"
                  id="password"
                  onChange={(e) =>
                    dispatch({
                      type: "passwordchange",
                      passwordChosen: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "catchpasswordErrors",
                      passwordChosen: e.target.value,
                    })
                  }
                  error={state.passwordErrors.hasErrors ? true : false}
                  helperText={state.passwordErrors.errorMessage}
                  fullWidth
                  required
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox
                  required
                  onChange={(e) =>
                    dispatch({
                      type: "acceptTermsAndCondition",
                      value: e.target.checked,
                    })
                  }
                />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </MDTypography>
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  Terms and Conditions
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  disabled={!state?.acceptTAndC}
                >
                  sign up
                </MDButton>
                {renderSuccessSB}
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
}

export default Cover;
